#settings {
  section {
    &#delay {
      margin-top: 1.1rem;
      p {
        display: inline;
      }
    }
  }
  .validation-delay {
    display: inline;
    vertical-align: sub;
    div {
      &.select-styles {
        display: inline-block;
        width: 90px;
        margin: 0.5rem 0.5rem 0 0.5rem;
        vertical-align: text-bottom;
        color: $dark-blue;
        background: #fafafa url('./../images/svg/fleche-bas.svg') no-repeat 90%
          50%;
        background-size: 13px;
        font-family: 'Futura-Book';
        text-align-last: center;
        border-radius: 5px;
        select {
          _::-webkit-full-page-media,
          _:future,
          :root .safari_only {
            text-align-last: right;
            padding-right: 29px;
            direction: rtl;
          }
        }
        /* Safari 10.1+ (alternate method) */
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            select {
              text-align-last: right;
              padding-right: 29px;
              direction: rtl;
            }
          }
        }
      }
    }
    p {
      display: inline-block;
      vertical-align: super;
    }
  }
  .container-select-nbr-guests {
    margin-bottom: 24px;
    .input-select-nbr-guests {
      display: flex;
      align-items: baseline;
    }
    .input-nbr-guests {
      line-height: 1.6;
      outline: none;
      border: none;
      width: 88px;
      text-align: center;
      padding: 0;
    }
    input[type='number']::-webkit-inner-spin-button, 
    input[type='number']::-webkit-outer-spin-button { 
    opacity: 1;
    }
    .select-styles {
      width: 90px;
      color: $dark-blue;
      margin: 0.5rem 0.5rem 0 0.5rem;
      border-radius: 5px;
      .select {
        padding: 5px 8px;
        font-family: 'Futura-Book';
        text-align-last: center;
      }
    }
  }
  .slot-avg-rotation {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 8px;
    width: 90px;
    font-size: 16px;
    padding: 5px 8px;
    font-family: 'Futura-Book';
    box-shadow: #bfbcbc 0 1px 2px 0;
    option {
      text-align: center;
    }
  }
  .particular-info {
    button {
      &.save {
        margin-top: inherit;
        margin-bottom: 2.5rem;
        display: block;
        font-family: 'Futura-Book';
      }
    }

    .header {
      margin: 1.5rem 0 1rem 0;
      h4 {
        display: inline;
      }
      .tooltip {
        svg {
          color: #4a4a4a;
        }
      }
    }
  }
}
section,
div {
  &.content-closing-days {
    h4 {
      margin-bottom: 0;
    }
  }
  &.closing-days {
    margin: 0.5rem 0;
    button {
      cursor: text;
    }

    img {
      _::-webkit-full-page-media,
      _:future,
      :root .safari_only {
        vertical-align: text-bottom;
      }
    }
    /* Safari 10.1+ (alternate method) */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        img {
          vertical-align: text-bottom;
        }
      }
    }
    .start,
    .end {
      button {
        letter-spacing: 1px;
      }
    }
    .start {
      p {
        margin-left: 2rem;
      }
    }
    .end {
      img {
        _::-webkit-full-page-media,
        _:future,
        :root .safari_only {
          vertical-align: middle;
        }
      }
      /* Safari 10.1+ (alternate method) */
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          img {
            vertical-align: middle;
          }
        }
      }
    }
    .select-styles {
      background: #fafafa;
      width: 100px;
      border-radius: 5px;
      padding: 0.2rem 0rem;
      position: relative;
      display: inline-block;
      color: $dark-blue;
      font-family: sans-serif;
    }
    .content-days {
      display: inline-block;
    }
    .container-arrow {
      position: relative;
      .arrow {
        position: absolute;
        bottom: 13px;
        right: -8px;
      }
    }
  }
  @media screen and (max-width: 769px) {
    img {
      &.calendar {
        margin-right: 0.7rem;
        vertical-align: top;
      }
      &.arrow {
        margin: 0 0.7rem;
        vertical-align: 0.5rem;
      }
    }
  }
}

.cta-save {
  float: right;
  margin-top: 2rem;
}
#calendar {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 0px #bfbcbc;
  position: relative;
  width: 325px;
  &.index-0 {
    bottom: 10.2rem;
  }
  &.index-1 {
    bottom: 7.2rem;
  }
  &.index-2 {
    bottom: 4.2rem;
  }
  &.index-3 {
    bottom: 1.2rem;
  }
  .container {
    padding: 0;
    display: inline-block;
    height: 300px;
  }
  button {
    bottom: 2rem;
  }
  .DayPicker-Day--today {
    color: $red;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $blue;
  }
  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #dbf8ff;
    color: $dark-blue;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .DayPicker-Month {
    margin-left: 6px;
  }
  .DayPicker-Caption {
    div {
      font-size: 1rem;
      font-family: 'Nunito Sans', sans-serif;
      margin-top: 0.3rem;
    }
  }
  .DayPicker-NavBar {
    span {
      &.DayPicker-NavButton--next {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $red url('./../images/svg/arrow-white-right.svg') no-repeat
          center;
        background-size: 5px;
      }
      &.DayPicker-NavButton--prev {
        margin-right: 1.7rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $red url('./../images/svg/arrow-white-left.svg') no-repeat
          center;
        background-size: 5px;
      }
    }
  }
}
