#hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  position: relative;
  .container-display-day {
    p {
      height: 60px;
      width: 65px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-family: 'Futura-Book';
    }
  }
  #container-slots {
    display: flex;
    max-width: 900px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $info;
      border-radius: 10px;
    }
  }
  .container-schedule-days {
    display: flex;
  }
  .container-slot {
    font-family: 'Futura-Light';
    font-size: 18px;
    .title-slot {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      margin: 0;
      font-family: 'Futura-Book';
    }
  }
  img {
    &.calendar {
      vertical-align: middle;
      @media screen and (max-width: 769px) {
        vertical-align: top;
        margin-top: 3rem;
      }
    }
  }
  .submit {
    button {
      &.disabled {
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }
  .time-picker {
    div {
      &.start,
      &.end {
        display: inline-block;
        p {
          &.subtitle {
            margin-left: 0.5rem;
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            img {
              vertical-align: middle;
            }
          }
        }
      }
      &.select-styles {
        display: inline-block;
        margin: 0 0.5rem;
        input {
          &.rc-time-picker-input {
            box-shadow: inherit;
            border: inherit;
            background: inherit;
          }
        }
      }
      .arrow {
        vertical-align: super;
      }
      .time {
        @extend %icon-svg;
        width: 25px;
        bottom: 0.6rem;
      }
    }
    .content-time {
      display: inline-block;
    }
  }
  .days {
    height: 30px;
    width: 285px;
    vertical-align: sub;
    background: #fafafa;
    span {
      border-right: 1px solid $light-grey;
      &:last-child {
        border-right: inherit;
      }
      span {
        border-right: inherit;
      }
    }
  }

  .content-days {
    display: flex;
    justify-content: center;
    // safari
    /*   @media screen and (min-width: 769px) and (max-width: 1200px){
      .select-days {
        _::-webkit-full-page-media, _:future, :root .safari_only {
          width: 80%;
          vertical-align: middle;
      }
      }
          @media not all and (min-resolution:.001dpcm)
          { @supports (-webkit-appearance:none) {

            .select-days { 
              width: 80%;
              vertical-align: middle;
              }
          }
        }

      }
     }
    */
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      .select-days {
        width: 85%;
        vertical-align: middle;
        @media screen and (min-width: 1170px) {
          /* img {
          vertical-align: bottom;
          margin-bottom: 10px;
         } */
        }
      }
    }

    /* Safari 10.1+ (alternate method) */

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        @media screen and (min-width: 769px) and (max-width: 1200px) {
        }
      }
    }

    .select-days {
      display: inline-block;
      .bloc-day {
        color: $light-grey;
        background: white;
        box-shadow: 0px 1px 1px 0px #b1a9a9;
        width: 50px;
        height: 40px;
        padding: 0.1rem 0;
        letter-spacing: normal;
        display: inline-block;
        cursor: pointer;
        &.selected {
          background: $blue;
          color: white;
        }
        &.update {
          background: $light-green;
          color: white;
        }
        span {
          display: block;
          padding-top: 0.3rem;
          text-align: center;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        @media screen and (max-width: 769px) {
          width: 45px;
        }
      }
      @media screen and (max-width: 769px) {
        width: 95%;
      }
    }
  }

  .container-switchs {
    font-size: 18px;
    p,
    .container-switch-button-time {
      height: 60px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Futura-Book';
    }
  }

  .container-add-slot-close-range {
    flex: 0 1 auto;
    margin-left: auto;
    display: flex;
    width: 350px;
    justify-content: space-between;
    p {
      color: $red;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .container-text-time-range {
    display: flex;
    justify-content: space-around;
    font-family: 'Futura-Book';
    align-items: center;
    color: $dark-blue;
    margin-top: 30px;
    width: 300px;
    p {
      margin: 0;
    }
  }

  .range-time-empty {
    text-align: center;
  }

  .container-time-picker-empty {
    cursor: pointer;
  }
  .container-time-picker-empty,
  .container-time-picker {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    div {
      display: flex;
      &.end {
        img {
          width: 0.6rem;
          margin: 0 0.5rem;
          margin-bottom: 0.3rem;
        }
        &.start,
        &.end {
          display: inline-block;
          img {
            vertical-align: super;
          }
        }
      }
      &.start {
        img {
          width: 23px;
          margin-right: 10px;
          vertical-align: super;
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            img {
              margin-top: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .content-time {
      display: inline-block;
    }
  }

  .time-picker-disabled,
  .disabled-button-time-picker {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .container-button-picker {
    margin: 10px 0 0 10px;
  }
}

.rc-time-picker {
  input {
    box-shadow: inherit;
    border: inherit;
    background: inherit;
  }
}
.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  &[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
  }
}

.rc-time-picker-panel-inner {
  font-family: 'Futura-Book';
  color: $red;
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.rc-time-picker-panel-narrow {
  max-width: 120px;
}
.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  font-size: 20px;
  text-align: center;
}
.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px 10px 6px 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}
.rc-time-picker-input[disabled] {
  color: $red;
}
.rc-time-picker-panel-clear-btn-icon {
  &:hover:after {
    color: #666;
  }
  &:after {
    content: 'x';
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    width: 20px;
    transition: color 0.3s ease;
  }
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  &:first-child {
    border-left: 0;
    margin-left: 0;
  }
  &:last-child {
    border-right: 0;
  }
  ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      list-style: none;
      box-sizing: content-box;
      margin: 0;
      padding: 0 0 0 22px;
      width: 100%;
      height: 24px;
      line-height: 24px;
      text-align: left;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        background: #edfaff;
      }
    }
  }
}
li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
