@import 'variables.scss';
@import '_admin_hours.scss';
@import '_admin_informations.scss';
@import '_admin_settings.scss';
@import '_admin_login.scss';
@import '_admin_integration.scss';


#admin {
  //background-image: linear-gradient(to bottom right, #fbfbfb, #eeedf2);
  min-height: 100%;
  background: $bg-admin;
  div {
    &.container {
      @media screen and (max-width: 769px){
        padding: 0 .5rem 0 1.5rem;
      }
    }
  }
  #title {
    h1 {
      display: inline-block;
    }
    #logout {
      float: right;
      font-family: 'Futura-Bold', sans-serif;
      span {
        color: $light-grey;
        margin-left: .2rem;
        vertical-align: super;
        font-weight: bold;
      }
      svg {
        width: 1rem;
        color: $grey;
      }
    }
  }
}
%input {
  box-shadow: none;
  border-radius: 7px;
  padding: 0.6rem;
  width: 100%;
  border-color: transparent;
  box-shadow: 0px 1px 1px 0px #b1a9a9;
}

%line {
  content:"";
  position: absolute;
  bottom: -2px;
  border-bottom: 1px solid $red;
  opacity: .2;
  bottom: 2px;
}
%margin-footer {
  margin-bottom: 4rem;
}

%icon-svg {
  vertical-align: super;
}
%line-menu {
  content:"";
  position: absolute;
  bottom: 6px;
  opacity: .2;
  border-bottom: 1px solid $red;
}

header {
  color: black;
  .content {
    padding: 2rem 0.3rem 0rem 1rem;
    @media screen and (min-width: 769px) {
      padding: 2rem 2rem 1rem 4rem;
    }
    h1 {
      &.title {
       color: $light-grey;
       font-family: 'Futura-Bold', sans-serif;
       @media screen and (min-width: 990px){
        width: 60%;
      }
      }
       &.logo {
         color: $red;
         font-size: 1.5rem;
         margin-bottom: 2rem;
         img {
          width: 140px;
         }
       }
     } 
     background: white;
  }
  .bg-white {
    height: 50px;
    margin-top: -.8rem;
    background: url('./../images/svg/background-config.svg') no-repeat center;
    background-size: 190% auto;
    background-color: $bg-admin;
    @media screen and (max-width: 769px) {
      margin-top: -1.5rem;
    }
   }
  ul {
    text-align: center;
    z-index: 2;
    @media screen and (max-width: 769px){
      padding-left: 0;
      padding-right: .5rem;
    }
    li {
      &:nth-child(2), &:nth-child(3) {
        a {
          &::after {
           @extend %line;
           left: 0;
           right: 0;
          }
          &.topLine {
            &::after{
            top: 2.3rem;
          }
          @media not all and (min-resolution:.001dpcm)
          { @supports (-webkit-appearance:none) {
            &::after{
              top: 2.45rem;
            }
          }
        }
        }
        }
      }
      &:last-child {
        a {
          &::after {
            @extend %line;
            left: 0;
            width: 50%;
            right: 0;
          }
          &.topLine {
            &::after{
            top: 2.3rem
           }
           @media not all and (min-resolution:.001dpcm)
           { @supports (-webkit-appearance:none) {
             &::after{
               top: 2.45rem;
             }
           }
         }
          }
        }
      }
      &:first-child{
        a {
          &::before {            
            content:"";
            position: absolute;
            bottom: 2px;
            right: 0;
            width: 50%;
            border-bottom: 1px solid #ffdfe0;
          }
          &.topLine {
            &::before{
            top: 2.3rem
           }

            /* Safari 10.1+ (alternate method) */
            @media not all and (min-resolution:.001dpcm)
            { @supports (-webkit-appearance:none) {
              &::before{
                top: 2.45rem;
              }
            }
          }

          }
          }

        }
      }

    li {
      display: inline;
      position: relative;
      a {
        display: inline-block;
        position: relative;
        padding: 0 5%;
        height: 35px;
        @media screen and (max-width: 769px){
          padding: 0 .3rem;
        }
        hr {
          background-color: $red;
          height: 4px;
          width: .5rem;
          text-align: center;
          margin: 0 auto;
          border: none;
          position: relative;
          z-index: 3;
        }
        p {
          color: $red;
          font-weight: bold;
          opacity: .5;
          font-size: 14px;
          letter-spacing: 1px;
          margin: 0;
          margin-bottom: 13px;
          @media screen and (max-width: 769px) {
            font-size: 13px;
          }
        }
        &.active {
          p {
            font-size: 16px;
            opacity: 1;
            letter-spacing: 0;
            font-family: 'Futura-Bold', sans-serif;
          }
          hr {
            width: 2.5rem;
            bottom: 0px;
          }
          &::after, &::before{
            bottom: 0px;
          } 

        } 
      }
    }
  }
}
.content-admin {
  background: $bg-admin;
}

 div {
    &.circle-orange {
    background-color: $dark-yellow;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    transform: translate(80%, -65%);
    float: right;
    @media screen and (max-width: 769px) {
      transform: translate(15%, -65%);
    }
  }

  &.circle-blue {
    background-color: $blue;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    transform: translate(80%, -65%);
    float: right;
    &.big {
      width: 9rem;
      height: 9rem;
      transform: translate(90%, -55%);
      @media screen and (max-width: 769px) {
        transform: translate(40%, -5%);
      }
    }
    &.tiny {
      width: 1rem;
      height: 1rem;
      opacity: .5;
      transform: translate(950%, -655%);
      @media screen and (max-width: 769px) {
        transform: translate(900%, -655%);
      }
    }
  }
}

.submit {
  margin: 2rem 0 4rem 0;
  float: right;
}

img {
  &.arrow {
    width: .6rem;
    margin: 0 .5rem;
    vertical-align: super;
    margin-bottom: .3rem;
  }
  &.calendar {
    @extend %icon-svg;
    margin-right: .9rem;
    width: 20px;
  }
}

p {
  &.subtitle {
    letter-spacing: .1px;
    margin-bottom: 0.3rem;
    color: $grey;
  }
}

#booking-validation {
  .connect {
    margin: 4% 5%;
  }
  .information {
    margin-left: 1rem;
    border-left: 1px solid $red;
    padding-left: 1rem;
    textarea {
      width: 20rem;
      height: 5rem;
      border-radius: 5px;
      border-color: $light-grey;
      background: transparent;
    }
    .submit {
      float: inherit;
      div {
        display: inline-block;
        margin-right: .3rem;
      }
    }
    .text-informations {
      margin-top: 8px;
      margin-bottom: 8px;    }
  }
  .title-margin {
    font-weight: bold;
  }
  .info-notConfirm {
    max-width: 330px;
  }
  #bookingAlreadyModified {
    .info-client {
      margin-left: 1rem;
      padding: 0 .5rem;
      width: 370Px;
      border: 1px solid #d4d4d4;
    }
    .info-text {
      max-width: 65%;
    }
  }
  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }

}

#no-recovery-restaurantId {
  width: 50%;
  height: 35%;
  text-align: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media screen and (min-width: 1200px){
    width: 40rem;
  }
  header {
    height: inherit;
    padding: 1rem 2rem 0rem 1rem;
    h1 {
      &.title {
       color: $light-grey;
       font-family: 'Futura-Bold', sans-serif;
       @media screen and (min-width: 990px){
        width: 70%;
      }
      }
       &.logo {
         color: $red;
         font-size: 1.5rem;
         margin-bottom: 2rem;
         img {
          width: 140px;
         }
       }
     } 
  }
  main {
    width: 70%;
    display: inline-block
  }
}