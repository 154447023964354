:root {
  --color-red-50: #fff5f5;
  --color-red-600: #e53e3e;
  --color-red-700: #c53030;
  --color-gray-200: #edf2f7;
  --color-gray-600: #718096;
  --color-gray-700: #4a5568;
  -webkit-font-smoothing: antialiased;
}

.cancellation-page {
  min-height: 100vh;
  background-color: var(--color-red-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.text-center {
    text-align: center;
  }
}

.cancellation-card {
  width: 100%;
  max-width: 28rem;
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  overflow: hidden;
}

.cancellation-card__title {
  font-size: 2rem;
  background-color: var(--color-red-600);
  color: #FFFFFF;
  text-align: center;
  margin: 0rem;
  padding: 2rem;
  font-weight: 100;
  font-family: 'Futura-Book';
}

.cancellation-card__details {
  padding: 1.5rem;
}

.cancellation-card__restaurant {
  font-size: 1.125rem;
  font-weight: 600;
}

.cancellation-card__info {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  margin-bottom: 0.5rem;
}

.cancellation-card__info-icon {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.cancellation-card__message {
  padding: 0 1.5rem;
  text-align: center;
}

.cancellation-card__message--expired {
  color: var(--color-red-600);
  font-weight: 500;
  margin: 0;
}

.cancellation-card__actions {
  padding: 1.5rem;
  margin: auto;
  display: flex;
  justify-content: center;
}

.cancellation-card__splash {
  padding: 80px 0;
}

.cancellation-card__splash svg {
  animation: splash 1s normal forwards ease-in-out;
  animation-iteration-count: 1;
  color: #16a34a;
  width: 100px;
  height: 100px;
}

.cancellation-card__splash__text {
  margin-top: 20px;
}

@media (max-width: 640px) {
  .cancellation-card {
    max-width: 95%;
  }
}

@keyframes splash {
  from {
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}
