nav{
  &.hide { 
    visibility: hidden;
    display: none;
  }
  #menu {
    padding: 0;
    display: flex;
    justify-content: center;
    min-height: 90px;
    li {
      list-style-type: none;
      position: relative;
      a {
        .icon-menu {
          width: 3rem;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
        span {
          width: 2.3rem;
          height: 2.3rem;
          transition: .7 ease;
          &.circle {
            width: .55rem;
            height: .55rem;
            background: $red;
            border-radius: 50%;
            display: inline-block; 
            //transition: width .055s ease-in-out .06s, height .055s ease-in-out .06s;
          }
        }
        p {
          display: inline-block;
          color: $red;
          font-size: 20px;
          text-transform: uppercase;
          opacity: .4;
          margin: 0 25px 10px;
        }
        &.active {
          p {
            opacity: 1;
            font-weight: 600;
            transition: opacity .25s ease-in-out;
          }
          span {
            &.circle {
              width: 2.3rem;
              height: 2.3rem;
              background-size: 1.2rem;
              box-shadow:
                0px 3px 5px -1px rgba($dark-blue, .20),
                0px 6px 10px 0px rgba($dark-blue, .14),
                0px 1px 18px 0px rgba($dark-blue, .12);
         

              //transition: background-color .25s ease-in-out;
              &#date {
                background: $red url('./../images/svg/calendar-white.svg') no-repeat center;
                background-size: 1.2rem;
              }
              &#time {
                background: $red url('./../images/svg/time-white.svg') no-repeat center;
                background-size: 1.2rem;

              }
              &#diners {
                background: $red url('./../images/svg/diners-white.svg') no-repeat center;
                background-size: 1.2rem;

              }
              &#contact {
                background: $red url('./../images/svg/contact-white.svg') no-repeat center;
                background-size: 1.2rem;

              }
              &#validation {
                background: $red url('./../images/svg/check-white.svg') no-repeat center;
                background-size: 1.2rem;

              }
            }
          }
        }
        &.stay-active {
         p {
          opacity: 1;
          color: $blue;
          font-size: 16px;
          text-transform: inherit;
         }
         span {
          &.circle {
            width: 2.3rem;
            height: 2.3rem;
            background-size: 1.2rem;
            &#date {
              background: $blue url('./../images/svg/calendar-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
            &#time {
              background: $blue url('./../images/svg/time-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
            &#diners {
              background: $blue url('./../images/svg/diners-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
            &#contact {
              background: $blue url('./../images/svg/contact-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
            &#validation {
              background: $blue url('./../images/svg/check-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
            &#content {
              background: $blue url('./../images/svg/check-white.svg') no-repeat center;
              background-size: 1.2rem;
            }
          }
        }
      }
    }
      @media screen  and (max-width: 770px) {
        %line-menu {
          content: "";
          height: 0px;
          position: absolute;
          left: 1.5rem;
          z-index: -999;
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(7) {
          a {
            ::after {
              @extend %line-menu;
              border-left: 1px solid #ffc3c5;
              top: 60%;
            }
            &.stay-active {
              &::after {
                @extend %line-menu;
                border-left: 1px solid $blue;
                top: 50%;
              }
            }
          }
        }
        &:first-child{
          a {
            ::before {
              @extend %line-menu;
              border-left: 1px solid #ffc3c5;
              top: 50%;
            }
            &.stay-active {
              &::after {
                @extend %line-menu;
                border-left: 1px solid $blue;
                top: 50%;
              }
            }
          }
        }
        &:last-child {
          a {
            &::after {
              @extend %line-menu;
              border-left: 1px solid #ffc3c5;
              bottom: 50%;
            }
            &.stay-active {
              &::after {
                @extend %line-menu;
                border-left: 1px solid $blue;
                bottom: 50%;
              }
            }
          }
        } 
      }
    }
    @media screen and (min-width: 770px) {
      margin-top: 2rem;
      text-align: center;
      li {
        display: inline-block;
        text-align: center;
        height: 77px;
        min-width: 153px;
        padding-bottom: 10px;
        a {
          .icon-menu {
            height: 3rem;
            width: 7rem;
            span {
              vertical-align: sub;
            }
          }
          p {
            display: block;
            margin-top: 0;
            font-size: 18px;
          }
          &.active, &.stay-active {
            .icon-menu {
              span {
                top: 0.6rem;
                margin-top: -.25rem;
              }
            }
          }
          &.stay-active {
            p {
              font-size: 18px;
            } 
          }
        }
        %line-menu-horizontal {
          content: "";
          position: absolute;
          top: 15%;
          z-index: -999;
          left: 0;
          right: 0;
          transition: border-color .4s ease-in;

        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4){
          a {
            &::after {
              @extend %line-menu-horizontal;
              border-bottom: 1px solid #ffc3c5;

              
            }
            &.stay-active {
              &::after {
                @extend %line-menu-horizontal;
                border-bottom: 1px solid $blue;

              }
            }
          }
        }
        &:first-child{
          a {
            &::before {
              @extend %line-menu-horizontal;
              border-bottom: 1px solid #ffc3c5;
              left: 50%;
              right: 0;
            }
            &.stay-active {
              &::before {
                @extend %line-menu-horizontal;
                border-bottom: 1px solid $blue;

                left: 50%;
                right: 0;
              }
            }
          }
        }
        &:last-child {
          a {
            &::after {
              @extend %line-menu-horizontal;
              border-bottom: 1px solid #ffc3c5;
              left: 0;
              right: 50%;
            }
            &.stay-active {
              &::after {
                @extend %line-menu-horizontal;
                border-bottom: 1px solid $blue;
                right: 50%;
                left: 0;
              }
            }
          }
        } 
      }
    }
    @media screen and (min-width: 818px) and (max-width: 822px) {
      text-align: center;
    }
    @media screen and (min-width: 768px) and (max-width: 770px){
      //margin-left: 3.4rem;
      margin-top: 1rem;
      .date {
        #calendar-mobile {
          padding-left: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  nav {
    #menu {
      display: block;
    }
  }
}
