.contact-content {
  margin-left: 1.5rem; 
  margin: 0 auto;
  // form
  #contact {
    width: 95%;
    margin: 0 auto;

    ::placeholder {
      color: $light-grey;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: $light-grey;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
     color: $light-grey;
    }

   
    input:invalid {
      border-color: $red;
    }
    input:focus:invalid {
      outline: none;
    }
    .bloc-label {
      margin: 1.5rem 0;
      h4 {
        margin: 0;
      }
     
      .PhoneInput {
        padding: 1px;
        height: 2.8rem;
        border: 1px solid #ccc;
        width: 92.5%;
        border-radius: 5px;
        margin-top: .5rem;
        position: relative;
        top: 4px;

        .PhoneInputInput {
          height: 2.8rem;
          padding: 0;
          padding-left: .5rem;
          padding-top: 2px;
        }

       
        &.input-phone {
          height: 2.8rem;

          &.error-phone {
            border: 1px solid $red;
            margin: 0;
            margin-top: .5rem;
          }
          &.validation{
            background: #f1f9f1;
            border: 1px solid #aed0ae;
            margin: 0;
            margin-top: .5rem;
          }
        }
      }
    }
    
    button {
      margin-top: .5rem;
    }
    .submit-form {
      margin-right: 7%;
      margin-bottom: 3rem;
      @media screen and (min-width: 769px){
        float: right;
      }
     }
     @media screen and (min-width: 769px) {
       .bloc-label{
         width: 50%;
         display: inline-block;
         margin: 1rem 0;
         &:first-child {
           width: 100%;
         }
       }
     }
     @media screen and (min-width: 1024px){
       width: 60%;
       margin: inherit;
       input {
         &.input {
           height: 2.5rem;
         }
       }
     }
  }
  

  .cgu {
    font-weight: bold;
  }
  .submit-form {
    margin-top: 2.3rem;
    button { 
      pointer-events: none;
      opacity: .65;
      &.isValidated {
       pointer-events: initial;
       opacity: 1;
      }
    }
 }
 div {
   &.gender {
    button {
      &.empty {
        border-color: $red;
        &:hover {
          border: none;
        }
      }
    }
  }
 }

 @media screen and (max-width: 768px){
  margin-right: 2rem;
 }
}

