.timeslots {
	display: block;
	margin: 30px auto 0;
	width: 500px;

	&__content {
		padding: 20px 0;
		border-bottom: 1px dotted $light-grey2;

		&:last-child {
			border-bottom: none;
		}
	}

	&__list {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		padding: 0;

		&__item {
			float: left;
			display: flex;
			margin: 10px;

			&--active {
				input[type='radio'] + label {
					background-color: $dark-blue;
					color: #fff;
				}
			}

			&--disabled {
				opacity: 0.5;

				label:hover {
					box-shadow: none !important;
					cursor: not-allowed !important;
				}
			}

			input[type='radio'] {
				opacity: 0;
				position: fixed;
				width: 0;
			}

			label {
				display: inline-block;
				background-color: $blue20;
				padding: 10px 20px;
				font-size: 16px;
				border-radius: 4px;
				max-width: 35px;

				&:hover {
					cursor: pointer;
					box-shadow: 0px 0px 7px rgba($dark-blue, 0.5);
				}
			}

			input[type='radio']:checked + label {
				background-color: $dark-blue;
				color: #fff;
			}

			input[type='radio']:checked + label {
				background-color: $dark-blue;
				color: #fff;
			}

			input[type='radio']:focus + label {
				outline: 1px solid #000;
			}
		}
	}
}

.content-hours {
	button.is-red {
		display: block;
		margin: 0 auto 30px;
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.timeslots {
		margin: 0px auto;
		width: 100%;

		&__content {
			padding: 20px 5px;
		}

		&__list {
			justify-content: center;

			&__item {
				margin: 5px;
			}
		}
	}
}

// If supported, prefer grid
@supports (display: grid) {
	.timeslots {
		&__list {
			list-style-type: none;
			padding: 0;
			display: grid;
			grid-template-columns: repeat(auto-fill, 75px);
			grid-gap: 10px;
			justify-content: center;

			&__item {
				float: none;
				display: block;
				margin: 0;
			}
		}
	}
}
