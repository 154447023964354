@import 'variables.scss';

@font-face {
  font-family: 'Futura-Book';
  src: url('./../fonts/Futura/ FuturaStd-Book.otf') format('truetype');
}
@font-face {
  font-family: 'Futura-Bold';
  src: url('./../fonts/Futura/FuturaStd-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Futura-Light';
  src: url('./../fonts/Futura/FuturaStd-Light.otf') format('truetype');
}

%body-content-padding {
  padding: 0 4rem;
}
%bg-gradient-red {
  background: linear-gradient(90deg, #fc2a41 0, #ed4f56 50%, #ff5661);
}
%img-product-size {
  max-height: 130px;
  padding-right: 5rem;
  //max-width: 150px;
}
%img-size-2 {
  max-width: 90px;
  max-height: 90px;
}
%padding-bloc {
  padding: 2rem 1.7rem;
}
%border-radius {
  border-radius: 2rem;
}
%border-radius-mobile {
  border-radius: 2rem;
}
%hr {
  height: 6px;
  margin: 0.5em 0 0 0;
  background-color: $red;
  border: 0;
  width: 40px;
}

%title {
  font-family: 'Futura-Book-Bold', sans-serif;
  color: $dark-blue;
  text-transform: uppercase;
}

%h4 {
  @extend %title;
  font-size: 22px;
}

%cta-shadow-hover {
  transition-duration: 0.55s;
  box-shadow: -1px 5px 10px 1px #a6a6a6;
}
%bloc-shadow {
  box-shadow: -3px 1px 20px 0px #dedede;
}

%cta-transition-color {
  transition: background-color 3s ease-out;
  transition: color 1s ease;
}

%handler-message-request {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #b5b5b5;
  text-align: center;
}

html {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  margin: 0;
  height: -webkit-fill-available;
  min-height: 100%;
  overflow-x: hidden;
  background: #fff;
  color: $dark-blue;

  //remove scroll
  overflow-x: hidden;
  overflow-y: scroll;
}

button,
a {
  border: none;
  background-color: initial;
  &:focus {
    outline: 0;
  }
  &.cta-delete {
    font-size: 0.6rem;
    margin-left: 0.3rem;
    position: relative;
    svg {
      color: $dark-blue;
      vertical-align: bottom;
    }
  }
  &.is-red {
    background-color: $red;
    @extend %border-radius;
    padding: 0.9rem 2rem;
    color: white;
    box-shadow: -1px 1px 10px 0px #cecece;
    transition: background-color 3s ease-out;
    transition: color 1s ease;
    &:hover {
      background-color: $red;
      color: white;
      @extend %cta-shadow-hover;
    }
    @media screen and (max-width: 769px) {
      a {
        color: white;
      }
    }
    span {
      font-family: "Futura-Book";
    }
  }
  &.is-red-line {
    @extend %border-radius-mobile;
    padding: 0.5rem 2rem;
    background-color: inherit;
    color: $red;
    border: 1px solid $red;
    font-family: 'Futura-Book';
    &:hover {
      background-color: $red;
      color: white;
      @extend %cta-shadow-hover;
    }
    @media screen and (max-width: 769px) {
      a {
        color: white;
      }
    }
  }
  &.is-blue {
    background-color: $blue;
    @extend %border-radius-mobile;
    padding: 0.9rem 1.2rem;
    color: white;
    margin: 0 0.5rem;
    transition: background-color 3s ease-out;
    transition: color 1s ease;
    &:hover {
      background-color: $blue;
      color: white;
      @extend %cta-shadow-hover;
    }
    @media screen and (max-width: 769px) {
      a {
        color: white;
      }
    }
  }
  &.is-blue-line {
    @extend %border-radius-mobile;
    background-color: inherit;
    padding: 0.6rem 1rem;
    color: $dark-blue;
    margin: 0 0.5rem;
    border: 1px solid $blue;
    &:hover {
      background-color: $blue;
      color: white;
      @extend %cta-shadow-hover;
    }
    @media screen and (max-width: 769px) {
      a {
        color: white;
      }
    }
  }
}

h1,
h2,
h3,
h4 {
  font-family: 'Futura-Bold', sans-serif;
  //letter-spacing: .5px
}
h4 {
  font-family: 'Futura-Bold', sans-serif;
  font-weight: 800;
}
h2 {
  font-weight: bold;
  color: $dark-blue;
}
a {
  text-decoration: none;
}
p {
  font-family: 'Futura-Book', sans-serif;
}

.container {
  @extend %body-content-padding;
  @media screen and (min-width: 1700px) {
    padding: 0 23%;
  }
}

.select-styles {
  //border: 1px solid #ccc;
  box-shadow: 0px 1px 1px 0px #b1a9a9;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
  background-size: 13px;
  position: relative;
  display: flex;
  font-family: 'Futura-Book';
  color: $red;
  .select-start {
    text-align-last: right;
    direction: rtl;
  }
  .select-end {
    text-align-last: left;
    direction: ltr;
  }
  .select {
    padding: 5px 8px;
    width: 90px;
    border: none;
    box-shadow: none;
    background: white;
    background-image: none;
    -webkit-appearance: none;
    //letter-spacing: .5px;
    &:focus {
      outline: none;
    }
    option {
      &.item {
        font-weight: bold;
      }
    }
  }
}

.error {
  margin: 1rem;
  text-align: center;
  //background-color: #ffebd2;
  border: 1px solid;
  border-radius: 5px;
  padding: 1rem;
  color: $red;
  @media screen and (min-width: 769px) {
    width: 40%;
    margin: 2rem auto;
  }
}

.error-form {
  font-size: 14px;
  color: $red;
}

.error-border {
  @extend .success-post;
}

.was-validated {
  .form-control {
    &:valid ~ .invalid-feedback {
      display: none;
    }
  }
  .error-form-input {
    &:invalid {
      border: 1px solid $red;
    }
  }
}

.success {
  background-color: $blue;
  padding: 0.6rem;
  border-radius: 3px;
  margin: 1rem 0;
  color: white;
  text-align: center;
}

.success-post {
  margin-bottom: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(143, 143, 143, 0.5);
}

/*
p,
ul,
li {
  color: #373737;
  font-weight: 500;
}

h1 {
  font-family: 'Futura-Medium', sans-serif;
  color: #000;
}

.is-touch {
  background-color: #ec9a30;
  color: white;
}

.error {
  margin: 1rem;
  text-align: center;
  background-color: #fef9f3;
  padding: 1rem;
  color: $red;
}

.error-form {
  font-size: 14px;
  color: $red;
}

.error-border {
  @extend .success-post;
}

.was-validated {
  .form-control {
    &:valid~.invalid-feedback {
      display: none;
    }
  }
  .error-form-input {
    &:invalid {
      border: 1px solid $red;
    }
  }
}

.success {
  background-color: #d7f3d7;
  padding: 0.6rem;
  border-radius: 3px;
  margin: 1rem 0;
  color: #043b00;
  text-align: center;
}

.success-post {
  margin-bottom: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(143, 143, 143, 0.5);
}



@media (min-width: 769px) and (max-width: 990px) {
  .time {
    .columns {
      width: 500px !important;
    }
  }
} */

/* .info {
  font-style: italic;
  color: $dark-blue;
  margin: 0 0 1rem 0;
  font-weight: inherit !important;
} */

.validation {
  textarea {
    border: 1px solid #a2a2a2;
    padding: 0.5rem;
    width: 90%;
    height: 5rem;
    margin: 0.5rem 0 1.5rem 0;
    border-radius: 5px;
  }
  .bloc-label {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    &.error-booking {
      text-align: center;
      color: #e84249;
    }
    input {
      &[type='checkbox'] {
        width: 10px;
        margin-right: 0.5rem;
        -webkit-appearance: none;
        background-color: #fafafa;
        border: 1px solid $blue;
        padding: 0.75rem;
        border-radius: 5px;
        display: inline-block;
        position: relative;
        outline-style: none;
        cursor: pointer;

        &:checked {
          background-color: $blue;
          border: 1px solid $blue;
           &::after {
              content: '\2714';
              font-size: 22px;
              position: absolute;
              top: 0;
              left: 3px;
              color: #fff;

            }
        }
        &:active,
        &:checked:active {
          outline-style: none;
          border-color: transparent;
          border: 1px solid $blue;
        }
      }
    }
    .check_mark {
      display: inline-block;
      width: 85%;
      vertical-align: top;
    }
  }

  .pure-checkbox {
    display: flex;
    align-items: center;;
  }

  p {
    display: inline;
  }
  .user-form-no-valided {
    text-align: left;
    p {
      display: block;
    }
    .list {
      margin-left: 10px;
    }
  }
  button {
    //float: right;
    margin-right: 1.5rem;
    @media screen and (min-width: 769px) {
      float: right;
      margin-bottom: 2rem;
    }
  }

  .popup {
    h1 {
      letter-spacing: inherit;
    }
    .popup_inner {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  iframe {
    height: 500px;
    border: none;
    @media screen and (max-width: 769px) and (min-width: 429px) {
      height: 380px;
    }
    @media screen and (max-width: 821px) and (min-width: 819px) {
      height: 630px;
    }
    @media screen and (min-width: 1024px) {
      height: 580px;
    }
  }

  @media screen and (min-width: 801px) {
    width: 60%;
    margin: 0 auto;
    //margin-left: 1.5rem;
    .popup {
      .popup_inner {
        top: 3%;
        bottom: 3%;
        left: 5%;
        right: 5%;
        font-family: 'Futura-Book', sans-serif;
        .content {
          margin-top: 0;
        }
      }
    }
  }
}

.bold {
  font-weight: bold;
}

#loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.textarea {
  width: 20rem;
  height: 5rem;
  border-radius: 5px;
  border-color: #a9aabc;
  background: transparent;
  margin-bottom: 1rem;
  font-family: 'Futura-Book';
}

.button-action {
  width: 32px;
  height: 32px;
  border-radius: 22.5px;
  border: solid 2px $red;
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: bolder;
  color: $red;
  cursor: pointer;
  outline: none;
}

.button-logout {
  display: flex;
  align-items: center;
}

.admin-fa-icon {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}

.delete-date {
  font-size: 20px;
}

.icon-check {
  color: green;
}

.icon-remove {
  color: $red;
  font-size: 12px;
}

.disabled-button {
  opacity: 0.3;
  cursor: not-allowed;
}
