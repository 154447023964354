%ctaWidth {
  width: 440px;
}

#integration {
  p {
    &.title {
      font-weight: 800;
      font-size: 15px;
      font-family: 'Futura-Bold', sans-serif;
    }
    .dot {
      height: 5px;
      width: 5px;
      margin-right: 5px;
      background-color: #262957;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .title-link {
    margin-bottom: 20px;
  }
  .link {
    width: 90%;
    margin-top: 1.5rem;
    margin-left: 15px;
    .url {
      margin-left: 15px;
      font-weight: bold;
      color: $dark-blue;
      transition: color 0.35s ease-out;
      &:hover {
        text-decoration: underline;
        color: $dark-blue;
      }
    }
    span {
      &.is-blue {
        padding: 1rem 0;
        margin-top: 0;
        background: $blue;
        border-radius: 25px;
        color: white;
        @extend %ctaWidth;
        display: inline-block;
        text-align: center;
      }
    }
    .container-change-url {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .container-label-input {
        width: 80%;
        input {
          width: 80%;
          padding: 10px;
          outline: none;
          margin: 0 0 0 15px;
          border-color: #b7b7b7;
          border-radius: 7px;
        }
      }
    }
  }
  p {
    a {
      color: $blue;
    }
  }
  .content {
    a {
      &.cta-link-fb {
        text-decoration: underline;
        color: $blue;
        margin-top: 1.5rem;
        display: block;
        font-family: 'Futura-Book', sans-serif;
      }
    }
  }
  .section-fb {
    margin-top: 0.9rem;
    width: 90%;
    @extend %margin-footer;
    h4 {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.2rem;
    }
    button {
      &.help {
        font-style: italic;
        color: $grey;
        text-decoration: underline;
        padding-left: 0;
        margin-bottom: 1rem;
        font-family: 'Futura-Light', sans-serif;
      }
    }
    .cta-facebook {
      margin-left: 15px;
      margin-top: 40px;
      @extend %ctaWidth;
      p {
        margin-top: 0;
      }
      button {
        &.login {
          margin-bottom: 1rem;
        }
        padding: 1rem;
        margin-top: 0;
        background: #3b5998;
        font-family: 'Futura-Book';
        border-radius: 25px;
        color: white;
        img {
          width: 30px;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }
    }
    div {
      &.popup-content {
        border-radius: 10px;
        p {
          margin: 1rem;
        }
      }
    }
    p {
      &.error {
        width: 60%;
        margin: 0;
        margin-top: 0.5rem;
        margin-left: 0;
      }
    }
  }
  .section-web {
    .script-web {
      width: 85%;
      border: 1px solid #b7b6b6;
      border-radius: 7px;
      padding: 0 1rem;
      margin-bottom: 1rem;
      pre {
        word-wrap: break-word;
        white-space: pre-wrap;
      }
      @media screen and (max-width: 769px) {
        width: 85%;
      }
    }
    button {
      margin-bottom: 4rem;
    }
  }
  .disable-app {
    width: 90%;
    h4 {
      margin-top: 2rem;
    }
    #disable-module {
      .popup {
        .popup_inner {
          width: 30%;
          height: 50%;
          @media screen and (max-width: 769px) {
            width: 70%;
          }
          @media screen and (min-width: 769px) and (max-width: 1110px) {
            width: 45%;
          }
          @media screen and (min-width: 1200px) {
            width: 30%;
            height: 40%;
          }
          @media screen and (min-width: 1630px) {
            width: 20%;
          }
          @media screen and (min-height: 649px) and (max-height: 1039px) {
            height: 33%;
          }
          @media screen and (min-height: 1040px) {
            height: 20%;
          }
          .radio {
            margin: 0.7rem 0;
          }
          [type='radio']:checked,
          [type='radio']:not(:checked) {
            position: absolute;
            left: -9999px;
          }
          [type='radio']:checked + label,
          [type='radio']:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: #666;
          }
          [type='radio']:checked + label:before,
          [type='radio']:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }
          [type='radio']:checked + label:after,
          [type='radio']:not(:checked) + label:after {
            content: '';
            width: 12px;
            height: 12px;
            background: $red;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
          [type='radio']:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
          [type='radio']:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }

          .form-disableApp {
            height: 90px;
          }
          #select-period-display-option {
            margin: 1rem 1rem 1.5rem 1.9rem;
            div {
              margin: 0.8rem 0;
              input[disabled] + label {
                opacity: 0.5;
              }
              label {
                margin-right: 1rem;
              }
            }
          }
          button {
            margin: 0 auto;
            display: block;
            &.disabled {
              opacity: 0.7;
            }
            &.isValidated {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.popup {
  z-index: 7687;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  .popup_inner {
    position: absolute;
    border-radius: 15px;
    left: 15%;
    right: 15%;
    top: 5%;
    bottom: 5%;
    margin: auto;
    background: white;
    h1 {
      display: inline-block;
      font-size: 18px;
      margin: 1.5rem 0 1rem 1rem;
    }
    @media screen and (max-width: 769px) {
      left: 10%;
      right: 10%;
      top: 15%;
      bottom: 15%;
      h1 {
        display: block;
      }
    }
    @media screen and (min-width: 1200px) {
      top: 4%;
      bottom: 4%;
    }
    div {
      &.content {
        margin: 1rem;
        font-family: 'Futura-Book', sans-serif;
        video {
          width: 50%;
          display: block;
          margin: 1rem auto;
          border: 1px solid $light-grey;
        }
        .link-mobile {
          margin: 0 0 0.5rem 0;
        }
        .script-web {
          width: 93%;
          border: 1px solid #b7b6b6;
          border-radius: 7px;
          padding: 1rem;
          margin-bottom: 1rem;
          word-wrap: break-word;
          white-space: pre-wrap;
          margin: 0 auto;
          @media screen and (max-width: 769px) {
            width: 87%;
          }
        }
        .confirm-copy {
          margin: 3px 3px 0 0;
          font-size: 14px;
          text-align: right;
          font-style: italic;
          color: $grey;
        }
        #content-tuto-fb {
          .warning {
            font-style: italic;
            color: grey;
            font-family: 'Futura-Book', sans-serif;
          }
          img {
            width: 70%;
            margin: 0 auto;
            display: block;
          }
          @media screen and (min-width: 1201px) {
            img {
              width: 55%;
              float: left;
              margin-left: 1rem;
              /* margin: 0 auto;
              display: block; */
            }
            ul {
              width: 35%;
              float: right;
            }
          }
        }
      }
    }
    span {
      svg {
        float: right;
        margin: 1rem;
        font-size: 1.5rem;
        color: $red;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

#fb-root {
  .fb_dialog {
    width: 70%;
    height: 35%;
    display: block;
  }
  // force iframe facebook to take 100% of his width
  iframe {
    width: 100% !important;
  }
}

#laddition {
  padding-bottom: 2rem;
  margin-right: 4rem;
  max-width: 400px;
  font-family: 'Futura-Book', sans-serif;
  h4 {
    img {
      width: 33px;
      vertical-align: bottom;
    }
  }
  form {
    div {
      &.form-laddition {
        input {
          border-color: #b7b7b7;
          border-radius: 7px;
          padding: 0.6rem;
          width: 100%;
          -webkit-box-shadow: 0px 1px 1px 0px #bfbbbb;
          box-shadow: 0px 1px 1px 0px #bfbbbb;
          padding: 0.5em 0.6em;
          display: inline-block;
          border: 1px solid #ccc;
          -webkit-box-shadow: inset 0 1px 3px #ddd;
          box-shadow: inset 0 1px 3px #ddd;
          border-radius: 4px;
          vertical-align: middle;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .info {
          font-style: italic;
          color: $grey;
        }
      }
    }
    p {
      &.error {
        width: 80%;
      }
    }
  }

  .container-steps-activation-resa {
    .title-activate-resa {
      font-family: 'Futura-Bold', sans-serif;
      font-weight: 800;
      margin-top: 30px;
    }
    ul {
      li {
        margin: 5px 0;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  &:hover {
    span {
      &.tooltipText {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  span {
    &.tooltipText {
      visibility: hidden;
      width: 260px;
      background-color: #4a4a4a;
      color: #eaeaea;
      text-align: center;
      border-radius: 6px;
      padding: 15px 0;
      position: absolute;
      z-index: 1;
      bottom: 154%;
      left: -486%;
      margin-left: -60px;
      transition: opacity 0.3s;
      padding: 1rem;
      line-height: 1.45rem;
    }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
}
