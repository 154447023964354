@import 'variables.scss';
@import '_client_menu.scss';
@import '_client_date.scss';
@import '_client_hours.scss';
@import '_client_nbGuest.scss';
@import '_client_contact.scss';
@import '_client_validationBooking.scss';
@import '_client_cancellationBooking.scss';

#client {
  header {
    h1 {
      font-family: 'Futura-Book', sans-serif;
    }
    .slogan {
      font-style: italic;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: white;
      opacity: 1;
      margin-top: 0;
    }
    #restaurant {
      .info {
        h2 {
          font-style: initial;
        }
      }
    }
    @media screen and (max-width: 1026px) {
      height: 250px;
      #book {
        h1 {
          color: white;
          font-weight: 100;
          font-size: 1.5em;
          margin: 1.2em 0 0 1rem;
        }
        background-image: url('./../images/svg/bkg-red-mobile.svg');
        background-repeat: no-repeat;
        width: 100%;
        background-size: 100% auto;
        position: absolute;
        height: 100%;
        z-index: -100;
        top: -5px;
      }
      #restaurant {
        background-image: url('./../images/svg/bkg-blue-mobile.svg');
        background-repeat: no-repeat;
        width: 80%;
        background-size: 100% auto;
        position: absolute;
        height: 100%;
        z-index: -99;
        //top: 4.5rem;
        top: 3.5rem;
        //left: 5.5rem;
        //left: 30px;
        right: 0;
        .info {
          z-index: 2;
          color: white;
          justify-content: right;
          text-align: right;
          margin-right: 15px;
          @media screen and (max-width: 376px) {
            margin-right: 15px;
          }
          h2 {
            z-index: 2;
            color: white;
            margin: 1.5rem 0 0.2rem 0;
            font-size: 1.8rem;
          }
          p {
            margin: 0.25rem 0 0 0;
            opacity: 0.8;
          }
        }
        @media screen and (min-width: 822px) and (max-width: 1024px) {
          background: url('./../images/svg/bkg-blue.svg') no-repeat;
          top: 3.5rem;
          left: 9rem;
          width: 67%;
          background-size: 100% 17%;
          .info {
            margin: 2rem 2.5rem 0 0;
            p {
              margin-top: 0.5rem;
            }
            h2 {
              font-size: 2.35rem;
              //margin-bottom: 1.5rem;
            }
          }
        }
      }
    }

    @media screen and (max-width: 320px) {
      div {
        &#restaurant {
          width: 300px;
          .info {
            width: 290px;
          }
        }
        &#book {
          background-size: inherit;
          top: -8px;
        }
      }
    }
    @media screen and (max-width: 429px) and (min-width: 321px) {
      div {
        &#restaurant {
          width: 320px;
          .info {
            width: 305px;
          }
        }
        &#book {
          background-size: 101% auto;
          top: -8px;
        }
      }
    }
    @media screen and (max-width: 430px) {
      #restaurant {
        background-image: url('./../images/svg/test-mobile-bgk-blue.svg');
        background-repeat: no-repeat;
      }
    }
    @media screen and (min-width: 431) and (max-width: 770px) {
      div {
        &#restaurant {
          width: 320px;
          .info {
            width: 305px;
          }
        }
        &#book {
          background-size: 101% auto;
          top: -3rem;
        }
      }
    }
    /*  @media screen and (min-width: 650px) and (max-width: 770px){
      #book {
        top: -5rem;
        //width: 145%;
        width: 100%;
        h1 {
          margin-top: 7rem;
        }
      }
      #restaurant {
        background: url('./../images/svg/bkg-blue-fb.svg') no-repeat;
        left: 25%;
        background-size: 130% auto;
        margin-top: -1rem;
        .info {
          margin-right: 25%;
        }
      }
      
      
    } */
    @media screen and (min-width: 768px) and (max-width: 1026px) {
      #book {
        background: url('./../images/svg/bkg-red.svg') no-repeat;
        top: -4rem;
        h1 {
          margin: 4.9rem 0 0 2rem;
          font-size: 28px;
        }
      }
      #restaurant {
        background-size: 100% 21%;
        background: url('./../images/svg/bkg-blue-fb.svg') no-repeat;
        top: 3.5rem;
        left: 11rem;
        .info {
          margin-right: 10rem;
          margin-top: 2rem;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 820px) {
      #book {
        top: -3rem;
      }
      #restaurant {
        .info {
          margin-right: 3rem;
        }
      }
    }
    @media screen and (min-width: 822px) and (max-width: 1023px) {
      div {
        &#book {
          background-size: 100%;
          top: -1.5rem;
          h1 {
            margin-top: 3rem;
          }
        }
        &#restaurant {
          left: 22rem;
          top: 1.5rem;
          .info {
            margin-right: 5rem;
            h2 {
              font-size: 2rem;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1023px) and (max-width: 1025px) {
      div {
        &#book {
          top: -3rem;
          background-size: 100%;
        }
        &#restaurant {
          left: 22rem;
          .info {
            margin-right: 4rem;
          }
        }
      }
    }
    @media screen and (min-width: 1025px) and (max-width: 1026px) {
      div {
        &#restaurant {
          .info {
            margin-right: 21rem;
            h2 {
              font-size: 2rem;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1027px) {
      padding: 2rem 1rem 1.5rem 3rem;
      background-color: #f7f7f7;
      height: 170px;
      .slogan {
        color: $dark-blue;
        margin-bottom: 15px;
      }
      #book {
        background: inherit;
        h1 {
          color: $red;
          text-transform: uppercase;
          font-size: 18px;
        }
      }
      #restaurant {
        background: inherit;
        .info {
          color: $dark-blue;
          text-align: left;
          h2 {
            color: $dark-blue;
            margin: 1rem 0 0rem 0;
            font-size: 1.95rem;
          }
          p {
            margin: 0.25rem 0 0 0;
            opacity: 0.7;
          }
        }
      }
    }

    @media screen and (max-width: 835px) and (min-width: 833px) {
      div {
        &#book {
          top: -33rem;
          h1 {
            margin-top: 37rem;
          }
        }
        &#restaurant {
          left: 20rem;
          top: 3.5rem;
        }
      }
    }

    @media screen and (min-width: 819px) and (max-width: 821px) {
      height: 220px;
      #book {
        top: -2rem;
        background-size: 100%;
        h1 {
          margin-top: 4.5rem;
        }
      }
      #restaurant {
        left: 14rem;
        top: 4rem;
        .info {
          margin-top: -18px;
          margin-right: 5.7rem;
          h2 {
            margin: 2.5rem 0 0.2rem 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 769px) {
    button {
      margin-top: 1rem;
      &:hover {
        box-shadow: none;
      }
      &.disabled {
        border: none;
        color: $light-grey;
        cursor: none;
        pointer-events: none;
      }
    }
  }

  button {
    &.is-red {
      border-radius: 25px;
      padding: 0.9rem 2rem;
      box-shadow: none;
    }
  }
  input {
    &.input {
      border: 1px solid #ccc;
      padding-left: 0.5rem;
      border-radius: 5px;
      height: 2.8rem;
      margin-top: 0.5rem;
      width: 90%;
      &:focus {
        outline-style: none;
        border-color: transparent;
        border: 1px solid #ccc;
      }
    }
    &:valid {
      &.validation {
        background: #f1f9f1;
        border: 1px solid #aed0ae;
      }
    }
  }
  p {
    &.hours-info {
      width: 90%;
      margin: 0 auto;
      margin-top: 1rem;
      font-size: 14px;
      font-style: italic;
      color: $grey;
      @media screen and (min-width: 769px) {
        bottom: 0;
        margin-bottom: 0.5rem;
        position: absolute;
        margin-left: 0.5rem;
      }
    }
  }
}

textarea {
  outline-style: none;
  border-color: transparent;
  border: 1px solid #a2a2a2;
  padding: 0.5rem;
  width: 90%;
  height: 5rem;
  margin-top: 0.5rem;
  border-radius: 5px;
}

body {
  &#being-created {
    background: $dark-blue;
    .being-created {
      width: 50%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 100vh;
      color: white;
      @media screen and (max-width: 769px) {
        width: 70%;
      }
      div {
        &.image {
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          background: $red;
          margin-bottom: 2rem;
          h2 {
            font-weight: bold;
            color: white;
            text-align: center;
            width: 9.9rem;
            height: 9.9rem;
            vertical-align: middle;
            display: table-cell;
          }
        }
      }
      span {
        &.name {
          font-weight: bold;
          font-size: 20px;
        }
      }
      a {
        color: $blue;
      }
    }
  }
}

#template-mobile {
  %line {
    content: '';
    left: 64px;
    position: absolute;
    z-index: -999;
  }
  .hide {
    visibility: hidden;
    display: none;
  }
  div {
    &.content {
      //.content-hours,
      #nbGuest,
      #contact,
      #validation {
        border-left: 1px solid $blue;
        &::after {
          @extend %line;
          //height: 20%;
          bottom: 75px;
          border-left: 1px solid $blue;
        }
      }
      #calendar-mobile {
        border-left: 1px solid $blue;
        &::after {
          @extend %line;
          //height: 30%;
          bottom: 75px;
          border-left: 1px solid $blue;
        }
        .select-period {
          &::after {
            border: none;
          }
        }
      }
      .content-hours {
        &::after {
          //height: 30%;
        }
      }
      #nbGuest {
        margin-left: 24px;
        &::after {
          height: 0;
        }
        ul {
          padding-left: 0;
        }
      }
      #validation {
        &::after {
          bottom: 215px;
        }
      }
    }
    &.no-select {
      .content-hours,
      #nbGuest,
      #validation {
        border-left: 1px solid #ffc3c5;
        &::after {
          @extend %line;
          //height: 30%;
          bottom: 145px;
          border-left: 1px solid #ffc3c5;
        }
      }
      #contact {
        border-left: 1px solid #ffc3c5;
        &::after {
          @extend %line;
          bottom: 145px;
          border-left: 1px solid #ffc3c5;
        }
      }
      #calendar-mobile {
        border-left: 1px solid #ffc3c5;
        &::after {
          @extend %line;
          bottom: 65px;
          //height: 250px;
          border-left: 1px solid #ffc3c5;
        }
      }

      #nbGuest {
        margin-left: 24px;
        height: 30%;
        &::after {
          height: 0;
        }
        ul {
          padding-left: 0;
        }
      }
      #validation {
        &::after {
          //height: 24%;
        }
      }
    }
    .link-hours {
      &::before {
        @extend %line;
        height: 30%;
        bottom: 165px;
        border-left: 1px solid #ffc3c5;
      }
    }
    form {
      &#contact {
        margin-left: 0px;
        padding-left: 0.85rem;
        width: 85%;
        margin-left: 24px;
      }
      &#validation {
        padding-left: 0.85rem;
        width: 90%;
        width: 85%;
        margin-left: 24px;
      }
    }
  }
}
