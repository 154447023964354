%arrow {
  border: none;
  width: 1.9rem;
  height: 1.9rem;
  display: inline-block;
  border-radius: 50%;
  margin: 0 .5rem;
  //top: calc(50% - 7px);
  right: 0px;
  cursor: pointer;
  vertical-align: middle;
 /*  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    transform: translate(0%, 130%); 
    //margin-top: 5rem;
  } */
}


%arrow-month {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1rem;
  margin-bottom: .3rem;
  cursor: pointer;
}

.date {
  div {
    &.pickerDate {
      text-align: center;
      padding: 0;
      div {
        &.cta-prev {
          @extend %arrow;
          background: $dark-blue url('./../images/svg/arrow-white-left.svg') center no-repeat;
          background-size: .6rem auto;
          left: 0;
          margin-top: 7px;

          &.disabled {
            filter: grayscale(1);
            opacity: .3;
            cursor: not-allowed;
          }
        }
        &.cta-next {
          @extend %arrow;
          background: $dark-blue url('./../images/svg/arrow-white-right.svg') center no-repeat;
          background-size: .6rem auto;
          right: 0;
          margin-top: 7px;
        }
      }
      #calendar-bloc {
        text-align: center;
        display: inline-block;
        margin: 0 .7rem;
        button {
          &.cta-day {
            //margin: 0rem .15rem 1rem 0.15rem;
            background: transparent;
            cursor: pointer;
            display: inline-block;
            width: 90px;
            padding: 0;
            margin-bottom: 1rem;
            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              border: none;
              &.name-day {
                height: 1.3rem;
                p {
                  color: #7d7d7d;
                  &.isToday {
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 900;
                  }
                }
              }
              &.content-day {
                border-radius: 50%;
                align-items: center;
                margin: 0 auto;
                position: relative;
                color: $dark-blue;
                div {
                  width: 18px;
                  text-align: center;
                  
                }
                @media screen and (min-width: 330px){
                  margin-top: .5rem;
                  padding: .2rem .2rem;
                  border: 2px solid $blue;
                  width: 2.5rem;
                  height: 2.5rem;
                }
                div {
                  margin: .7rem 0;
                  &.content-day-today {
                    margin-top: 1rem;
                  }
                }
                &.selectable {
                  background: $blue;
                  color: white;
                  font-family: 'Nunito Sans', sans-serif;
                }
                &.disabled {
                  color: grey;
                  opacity: .5;
                  border: 2px solid white;
                }
                &.past {
                  text-decoration-line: line-through;
                  border: 2px solid white;
                }
                &.overlap {
                  // border: 2px solid white;
                }
              }
              &.active {
                @media screen and (min-width: 330px){
                  color: white;
                  background: $blue;
                } 
                @media screen and (max-width: 329px){
                  border-bottom: 3px solid $blue;
                  color: $blue; 
                  border-radius: 0;
                  padding-bottom: 8px;
                }
                
              }
            }
          }
        }
      }
      #month {
        p {
          display: inline-block;
          color: $dark-blue;
          font-family: "Futura-Bold", sans-serif;
          text-transform: capitalize;
          font-size: 15px;
          @media screen and (max-width: 320px) {
            font-size: 12px
          }
        }
        .cta-prev-month {
          background: url('./../images/svg/arrow-prev-red.svg') center no-repeat;
          @extend %arrow-month;

          &.disabled {
            filter: grayscale(1);
            opacity: .3;
            cursor: not-allowed;
          }
        }
        .cta-next-month {
          background: url('./../images/svg/arrow-next-red.svg') center no-repeat;
          @extend %arrow-month;
        }
      }
    }
  }
  @media screen and (min-width: 769px){
    #days {
      .transformArrow {
        transform: translate(0%, 130%); 
      }
    }
  }
  @media screen and (max-width: 769px){
    .content {
      div {
        &.pickerDate {
          #month {
            margin: 1rem 0 0 2rem;
            div {
              &.cta-prev-month, &.cta-next-month {
                margin: 0;
              }
            }
          }
          div {
            &.cta-prev {
              margin: 0;
              display: inline-block;
              width: 30px;
              z-index: 266;
              float: left;
            }
            &.cta-next {
              margin: 0;
              display: inline-block;
              float: right;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 769px) {
  #template-mobile {
    #calendar-mobile {
      margin-left: 1.5rem;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      .select-days {
        margin: 1.5rem 0;
        .popup {
          .popup_inner {
            
            left: 0%;
            right: 0%;
            top: 0%;
            bottom: 0%;
            margin-left: 0;
            margin-right: 0;
            #days {
              width: 100%;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              #calendar-bloc {
                width: 80%;
                @media screen and (min-width: 329px) and (max-width: 769px){
                  width: 75%;
                }
                @media screen and (min-width: 770px){
                  width: 60%;
                }
              }
            }
          }
        }
      }
      .select-period {
        margin: 1.5rem 0;
        &::after {
          border: none;
        }
      }
    }
  }
}