#informations {
  select {
    &.timezone {
      width: 100%;
    }
  }
  label, input {
    font-family: 'Futura-Book'
  }
  .space-block {
    margin-right: 2rem;
  }
  .input-zipCode {
    @media screen and (min-width: 568px){
      margin-right: .95rem;
    }
  }
  .input-phone {
    .PhoneInputCountry {
      margin-right: 0;
      border: none;
    }
    .PhoneInputCountrySelectArrow {
      opacity: 1;
    }
    input {
      background-color: white;
    }
  }
  .error-phone {
    input {
      border-color: $red;
    }
  }
  .error-mail {
    color: $red;
    border-color: $red;
  }
  .address {
    label {
      margin-top: 0;
    }
    .no-write {
      pointer-events: none;
      background: inherit;
      box-shadow: none;
      z-index: 787878;
      /* top: 0; */
      margin-top: 0px;
      position: absolute;
      border: none;
    }
    .country-select {
      width: 100%;
      border-color: #b7b7b7;
      display: inline-block;

      .css-vj8t7z {
        border-radius: 7px;
        -webkit-box-shadow: 0px 1px 1px 0px #bfbbbb;
        box-shadow: 0px 1px 1px 0px #bfbbbb;
      }
      &.not-valid {
        .css-vj8t7z {
          border-color: red;
        }
      }
      .css-1hwfws3 {
        visibility: hidden;

      }
      .css-bl6clz {
       visibility: hidden;
       cursor: none;
      }

    }
  }
  .input-street {
    margin-top: 1rem;
    @media screen and (min-width: 568px){
      :first-child {
        width: 17%;
        margin-right: 5px;
      }
      label {
        margin-top: 0.35rem;
      }
    }
    @media screen and (min-width: 995px){
      :first-child {
        margin-right: 10px;
      }
    }
  }
  label {
    color: $grey;
    margin-top: 1rem;
  }
  input {
    @extend %input;
    &.input-email {
      background-color: white;
      color: #969696;
    }
  }
  button {
    float: right;
   //margin-top: 2rem
  }
  input:invalid {
    border-color: $red;
  }
  input:focus:invalid {
    outline: none;
  }
  button {
    pointer-events: none;
    opacity: .65;
    &.isValidated {
     pointer-events: initial;
     font-family: 'Futura-Book';
     opacity: 1;
    }
  }

}
