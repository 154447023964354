#validation-booking {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon-menu {
    width: 50px;
    height: 50px;
    background: $light-green;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 1.5rem;
    img {
      transform: translate(40%, 50%);
    }
  }
  .information {
    text-align: center;
    width: 80%;
    margin: 1.4rem auto;
    margin-bottom: 1rem;
    :first-child {
      display: block;
      margin-bottom: .7rem;
    }
    span {
      &.bold {
        font-weight: bold;
        display: inline;
        :first-child {
          display: inline;
        }
      }
    }
  }
  .cta-newBooking {
    button {
      display: block;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
  .booking-information {
    text-align: left;
    opacity: .6;
    margin-bottom: 20px;
    p {
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: .75rem;
        height: .75rem;
        border-radius: 50%;
        margin-right: 1.3rem;
        background-color: $light-green;
      }
      &:first-child {
        &::after {
          content: "";
          margin-top: 10px;
          height: 40px;
          position: absolute;
          left: 5px;
          border-left: 1px solid $light-green;
          z-index: -999;
        }

      }
     &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
      &::after {
        content: "";
        height: 40px;
        position: absolute;
        left: 5px;
        border-left: 1px solid $light-green;
        z-index: -999;
      }
     }
      &:last-child {
        &::after {
          border:none;
        }

      }
    }
  }
  @media screen and (min-width: 769px) {
    width: 60%;
    margin: 0 auto;
    .booking-information {
      p {
        &:first-child, &:last-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          &::after {
            left: 5px;
          }
        }
        &:last-child::after {
          border: none;
        }
      }
    }
  }
  
}

#validation-reload {
  width: 60%;
  margin: 0 auto;
  padding-left: 2rem;
  margin-top: 2rem;
  text-align: center;
  h3 {
   
    margin: 0.5rem 0;
  }
  a {
    margin-top: 2rem;
    &.is-red {
      text-align: center;
      border-radius: 25px;
      padding: .9rem 2rem;
      display: table;
      margin: 0 auto;
    }
  }
}

#validation {
  .form-content {
    .info {
      font-style: italic;
      color: $dark-blue;
      margin: 0 0 0 0;
      font-weight: inherit !important;
    }
    div {
      &.submit-form {
        width: 100%;
        height: 5rem;
        margin-top: .3rem;
        button {
          display: block;
          margin: 0 auto;
          text-align: center;
          float: inherit;
          width: 8.5rem;
          div {
            display: inline-block;
            margin-right: .5rem;
          }
        }
      }
    }
    span {
      &.cgu {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .legal-info {
    color: $grey;
    font-size: 14px;
    width: 95%;
    margin-bottom: 1rem;
    a {
      color: $grey;
    }
    p {
      display: block;
      &.cgu-link {
        a {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 769px) {
    .popup{
      .popup_inner {
        /* left: 1%;
        right: 1%;
        top: 1px;
        bottom: 1%; */
      }
    }
  }
}