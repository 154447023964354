#nbGuest {
  h3 {
    color: $red;
    text-align: center;
    font-family: "Futura-Medium", sans-serif;
    margin-top: 2rem;
  }
  .pick-guests {
    max-width: 500px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;

    button {
      display: inline-block;
      background-color: $blue20;
      padding: 10px 20px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 7px rgba($dark-blue, 0.5);
      }
    }
  }

  .show-more-button {
    display: block;
    margin: auto;
    max-width: 500px;
    background-color: #b3d4ff;
    color: #3366cc;
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    transition: background-color 0.3s ease;
  }

  .show-more-button:hover {
    background-color: #99c2ff;
  }

  .chevron-icon {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: 768px) {
    .pick-guests {
      margin-right: 2rem;
      margin-bottom: 1.5rem;
    }
    h3 {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .pick-guests {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
