$red: #e84249;
$blue: #5ac8f5;
$blue20: #e1f4fd;
$dark-blue: #262957;
$light-blue: rgba(36, 42, 88, 0.7);
// secondary color
$yellow: #f9b868;
$dark-yellow: #f49f57;
$grey: #636363;
$info: #858588;
$light-grey: #a9aabc;
$light-grey2: #bdbdbd;
$light-green: #c6da85;

$bg-admin: #f7f6f8;
$bg-grey: #f6f6f6;


